import "./App.css";
import MarProfile from "./images/mar_profile_new.JPG";
import ContactForm from "./ContactForm";
import FAQAccordion from "./FAQAccordion";
import { Link } from "react-scroll";
import { BiWorld } from "react-icons/bi";
import { useState } from "react";

function App() {
  const [language, setLanguage] = useState("english");

  const toggleLanguage = () => {
    setLanguage(language === "english" ? "spanish" : "english");
  };

  return (
    <div className="App">
      <div className="header-container">
        <header>
          <h1>
            <BiWorld
              style={{ height: "50px", width: "50px", color: "white" }}
            />
          </h1>
          <div className="header-links-container">
            <div className="toggle-container">
              <button className="toggle-button" onClick={toggleLanguage}>
                {language === "english"
                  ? "Cambia a Español"
                  : "Switch to English"}
              </button>
            </div>
            <Link
              to="contact-form-container"
              smooth={true}
              duration={500}
              className="header-links"
              activeClass="active"
            >
              {language === "english" ? "Contact" : "Contacto"}
            </Link>
          </div>
        </header>
      </div>
      <div className="banner">
        <h1>
          {language === "english"
            ? "Immigration Psycho-Social Evaluations and Psychotherapy:"
            : "Evaluaciones Psicosociales de Inmigración y Psicoterapia:"}
          <br />
        </h1>
        <h2>
          {language === "english"
            ? "Servicios Bilinguales"
            : "Bilingual Services"}
        </h2>
        <h2>Marissa Merrill, M.A., LMHC, LPC</h2>
        <h2 className="license">
          {language === "english"
            ? "Licensed in Washington, Arizona, Oregon & Colorado"
            : "Licenciada en Washington, Arizona, Oregon y Colorado"}
        </h2>
      </div>
      <div className="about-me">
        <div className="image">
          <img src={MarProfile} alt="Marissa" />
        </div>
        <div className="bio">
          <h3>
            {language === "english"
              ? "Hi, I'm Marissa Merrill"
              : "Hola, soy Marissa Merrill"}
          </h3>
          <p>
            {language === "english"
              ? `I am an Asian-American identifying and bilingual (Spanish and
            English) licensed mental health counselor who has been trained to
            provide psycho-social evaluations for both humanitarian visas
            (U-visa, T-Visa, Asylum, VAWA, etc.) and hardship/waiver cases
            (I-601, I-601A, Cancellation of Removal, etc.) in Washington, Arizona, Oregon and
            Colorado state. Previously, I have worked as a paralegal for an
            immigration attorney and am familiar with the various legal
            processes of filing for status. My approach aims to work closely
            with both client and attorney to provide quality detailed reports.
            Throughout the evaluation, my goal is to offer a compassionate and
            supportive environment as I know it can be challenging to discuss
            the past or impending traumatic event(s).`
              : `Soy una terapista de la salud mental licenciada por los estados de Washington, Arizona, Oregon y Colorado. Soy asiática-americana y bilingüe (inglés y español). Yo recibí entrenamiento para proveer evaluaciones psico-sociales para los casos de visas humanitarias (Visa-U, Visa-T, asilo y VAWA) y los casos de adversidad (I-601, I-601A, Cancelación de remoción, etc.). Antes de ser terapista, trabajé como asistente legal para una abogada de inmigración y estoy familiarizada con los procesos legales. Mi estrategia es trabajar acerca de Ud. y su abogado para proveer un informe detallado y de calidad. Yo sé que puede ser difícil confiar en alguien sobre sus traumas, entonces durante este proceso, mi meta es establecer la confianza entre nosotros y ofrecer un ambiente de compasión y apoyo.`}
          </p>
          <div className="approach-beliefs">
            <div>
              <h2>{language === "english" ? "Education:" : "Educación:"}</h2>
              <p>
                {language === "english"
                  ? `I graduated from the Seattle School of Theology and Psychology
                with a Masters in Counseling Psychology. I am also a graduate of
                the Immigration Evaluation Institute and have formal
                cross-cultural training. My past clinical experience has been
                primarily based in community mental health settings where I
                worked with diverse individuals and groups who have experienced
                trauma, depression, anxiety, grief, relationship issues and
                identity issues. I specialize in working with POC and
                undocumented clients. I have my LMHC and LPC.`
                  : `Me gradué de la Escuela Seattle de Psicología y Teología con un maestría en psicología de consejería. También me gradué del instituto de las evaluaciones de inmigración y tengo entrenamiento intercultural formal. En el pasado mi experiencia clínica se ha enfocado en la salud mental comunitaria donde trabaje con individuos y grupos diversos quienes han experimentado trauma, depresión, ansiedad, duelo, problemas con la identidad y las relaciones. Mi especialización es trabajar con los clientes marginalizadas y clientes sin documentos. Tengo mi LMHC y LPC.`}
              </p>
            </div>
            <div>
              <h2>
                {language === "english"
                  ? "Other Services:"
                  : "Otros Servicios:"}
              </h2>
              <p>
                {language === "english" ? (
                  <>
                    I also provide individual psycho-therapy for BIPOC clients.
                    To learn more about these services please click{" "}
                    <a
                      href="https://www.mosaiccounselingcollective.com/marissa-merrill-lmhc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                    .
                  </>
                ) : (
                  <>
                    También, proveo servicios de terapia para los clientes
                    BIOPIC. Para aprender más sobre estos servicios haga click{" "}
                    <a
                      href="https://www.mosaiccounselingcollective.com/marissa-merrill-lmhc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      aquí
                    </a>
                    .
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-section-container">
        <div className="faq-container">
          <FAQAccordion language={language} />
        </div>
        <div className="contact-section-container" id="contact-form-container">
          <div className="contact-section">
            <div>
              <h3>{language === "english" ? "CONNECT!" : "¡CONÉCTATE!"}</h3>
              <p>
                {language === "english"
                  ? `Reach out and let's get in touch! Briefly tell me who you are
                and why you're here. Please share your availability and
                preferred contact method.`
                  : `¡Comuníquese y pongámonos en contacto! Cuéntame brevemente quién eres
                y por qué estás aquí. Por favor comparte tu disponibilidad y
                método de contacto preferido.`}
              </p>
            </div>
            <ContactForm language={language} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
